@use '@angular/material' as mat;

@mixin page-route-list-theme($theme) {

  // retrieve variables from theme
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .gd-page-route-list__row {
    background-color: mat.get-color-from-palette($background, card);
  }

  .gd-data-list__cell:link,
  .gd-data-list__cell {
    color: mat.get-color-from-palette($foreground, text);
  }

}
