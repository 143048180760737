@use '@angular/material' as mat;

@mixin section-info-component-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  .gd-section-info__popper {
    background-color: mat.get-color-from-palette($primary, 400);
    color: mat.get-color-from-palette($background, card);
    .gd-section-info__popper-arrow {
      &::before {
        background-color: mat.get-color-from-palette($primary, 400) !important;
      }
    }
  }

  .gd-section-info__icon {
    color: mat.get-color-from-palette($background, card);
  }


  .--templates {

  .gd-section-info__popper {
    background-color: rgb(30, 30, 30);
    color: white;
  }

  .gd-section-info__popper-arrow::before {
    background-color: rgb(30,30,30) !important;
  }

  .ngxp__container {
    border-color: black;
  }

  .gd-section-info__popper-arrow-bg {
    background: rgb(30, 30, 30)
  }

  .gd-section-info__icon {
    color: white;
  }
  }

}
