.gd-data-list__cell:link,
.gd-data-list__cell:visited {
  cursor: pointer;
  text-decoration: none;
  //color: black;
}

.gd-data-list__cell--locked {
  cursor: pointer;
  color: gray;
  font-weight: 400;
}

.gd-data-list__cell {
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  // justify-content: center;
}

.gd-item-list__row:hover {
  background-color: rgb(245, 245, 245);
}

:host-context(.gd-test-theme) ::ng-deep .gd-item-list__row:hover {
  background-color: #333333 !important;
}

.gd-data-list__first-column,
.gd-data-list__first-column > *{
  font-weight: 600;
}



:host-context(.gd-test-theme)  .gd-data-list__cell {
  color: rgba(255, 255, 255, 0.87) !important; 
}


