@use '@angular/material' as mat;
@mixin images-view-theme($theme) {
  // retrieve variables from theme
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  .gd-indicator__circle.--primary {
    background: mat.get-color-from-palette($primary);
  }
  .gd-indicator__circle.--accent {
    background: mat.get-color-from-palette($accent);
  }

  .gd-indicator__circle.--system {
    background: rgb(190, 190, 190);

  }
}
