@use '@angular/material' as mat;

// The 3rd party widget color is optional, accent acent color will be used by default
@mixin node-palette-item-component-theme($theme, $widgets-3rd-party-color: null) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $text: map-get($theme, text);
  $foreground: map-get($theme, foreground);

  .gd-nodes-palette__item {
    position: relative;
    color: mat.get-color-from-palette($background, card);
  }

  .gd-nodes-palette__item::before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba( mat.get-color-from-palette($background, card), .5 );
    filter: blur(3px);
  }

  .gd-nodes-palette__layout-item {
    position: relative;
    border: 2px solid #42c8ff;
    border-top-width: 5px;
  }

  .gd-content-node .gd-nodes-palette__layout-item {
    background-color: rgba(  mat.get-color-from-palette($primary, 100), .4 );
    background-image: repeating-linear-gradient(-45deg, transparent, transparent 2px, mat.get-color-from-palette($background, card) 2px, mat.get-color-from-palette($background, card) 5px);
  }

  .gd-nodes-palette__layout.--child-layout mat-icon {
    color: #42c8ff;
  }

  .gd-content-node {
    transition: opacity .3s;
  }

  .gd-content-node.--disabled {
    opacity: .4;
  }

  .gd-nodes-palette-item__label {
    position: relative;
    color: mat.get-color-from-palette($primary, 200);
  }


  // Widget node style
  .gd-nodes-palette__item.gd-nodes-palette__widget-item .gd-nodes-palette__widget-item-frame {
    border-color: mat.get-color-from-palette($accent, 200);
  }



  .gd-nodes-palette__item.gd-nodes-palette__widget-item mat-icon,
  .gd-nodes-palette__item.gd-nodes-palette__widget-item  .gd-nodes-palette-item__label {
    color: mat.get-color-from-palette($accent, 200);
  }

  // 3rd party widgets
  $widgets-3rd-party-shade: if($widgets-3rd-party-color, mat.get-color-from-palette($widgets-3rd-party-color, 300), mat.get-color-from-palette($accent, A400)) ;

  .gd-system-widget {
    background-color: mat.get-color-from-palette($accent, 200);
  }

  .gd-third-party-widget {
    background-color: $widgets-3rd-party-shade;
  }

  .gd-nodes-palette__item.gd-nodes-palette__widget-item-3rd-party .gd-nodes-palette-item__label,
  .gd-nodes-palette__item.gd-nodes-palette__widget-item-3rd-party mat-icon {
    color: $widgets-3rd-party-shade;
  }

  .gd-nodes-palette__item.gd-nodes-palette__widget-item-3rd-party .gd-nodes-palette__widget-item-frame {
    border-color: $widgets-3rd-party-shade;
  }
}
