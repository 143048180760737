@use '@angular/material' as mat;

@mixin node-builder-component-theme($theme, $widgets-3rd-party-color: null) {

  // retrieve variables from theme
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  gd-node-builder {

    background-color: mat.get-color-from-palette($background, card);

    // container node and template nodes
    .gd-template-node__header {
      background-color: #42c8ff;
      color: mat.get-color-from-palette($background, card);
    }

    .gd-template-node:hover > .gd-template-node__header {
      background-color: #65aa71;
      color: mat.get-color-from-palette($background, card);
    }

    .gd-template-node__children {
      background-color: mat.get-color-from-palette($background, card);
    }

    .gd-template-node--child-layout .gd-template-node__children {
      position: relative;
      background-color: rgba(  mat.get-color-from-palette($primary, 100), .25 );

      background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, mat.get-color-from-palette($background, card) 4px, mat.get-color-from-palette($background, card) 8px);
    }


    .gd-template-node__children.gd-widgets-list {
      padding: 3px 6px 3px 6px;
    }

    .gd-template-node--widget {
      margin: 3px;
      padding: 0;
    }

    .gd-template-node--widget-container .gd-template-node__children::after {
      color: mat.get-color-from-palette($accent, 200);
    }

    .gd-template-node--widget-container:hover .gd-template-node__children::after {
      color: mat.get-color-from-palette($accent, 300);
    }

    .gd-template-node--child-layout .gd-template-node__children::after {
      color: mat.get-color-from-palette($primary, 300);
    }

    .gd-template-node--child-layout:hover .gd-template-node__children::after {
      color: mat.get-color-from-palette($primary, 400);
    }

    .gd-template-node--child-layout .gd-template-node__header {
      background-color: mat.get-color-from-palette($primary, 200);
    }

    .gd-template-node--child-layout .gd-template-node--content {
      background-color: mat.get-color-from-palette($background, card);
    }

    .gd-template-node--child-layout,
    .gd-template-node--widget-container {
      background-color: mat.get-color-from-palette($background, card) !important;
    }

    // widget container node
    .gd-template-node--widget-container .gd-template-node__children {
      position: relative;
      background-color: rgba(0 , 0, 0, 0.05);
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, mat.get-color-from-palette($background, card) 4px, mat.get-color-from-palette($background, card) 8px);
    }

    .gd-template-node--disabled.gd-template-node--widget-container .gd-template-node__children::after {
      color: rgba(0 , 0, 0, 0.3);
    }

    // widget node
    .gd-template-node--widget {
      background-color: mat.get-color-from-palette($background, card);
    }

    $widgets-3rd-party: if($widgets-3rd-party-color, mat.get-color-from-palette($widgets-3rd-party-color, 500), mat.get-color-from-palette($accent, A600));
    $widgets-3rd-party-shade: if($widgets-3rd-party-color, mat.get-color-from-palette($widgets-3rd-party-color, 300), mat.get-color-from-palette($accent, A400));

    .gd-template-node--widget .gd-template-node__header {
      background-color: mat.get-color-from-palette($accent, 200);
      color: mat.get-color-from-palette($background, card);
      &.--thirdParty {
        background-color: $widgets-3rd-party-shade;
      }
    }

    .gd-template-node--widget:hover .gd-template-node__header {
      background-color: mat.get-color-from-palette($accent, 400);
      &.--thirdParty {
        background-color: $widgets-3rd-party
      }
    }

    .gd-template-node--inherited-widget .gd-template-node__header {
      background-color: mat.get-color-from-palette($background, card);
      color: mat.get-color-from-palette($accent, 200);
      border: 3px solid mat.get-color-from-palette($accent, 200);
      opacity: 0.9;
      &.--thirdParty {
        color: $widgets-3rd-party-shade;
        border: 3px solid $widgets-3rd-party-shade;
      }
    }

    .gd-template-node--inherited-widget:hover .gd-template-node__header {
      color: mat.get-color-from-palette($accent, 400);
      background-color: mat.get-color-from-palette($background, card);
      border: 3px solid mat.get-color-from-palette($accent, 400);
      opacity: 1;
      &.--thirdParty {
        color: $widgets-3rd-party;
        border: 3px solid $widgets-3rd-party;
      }
    }

    .gd-node-builder__valid-widget-dropzone {
      transition: all .3s;
    }

    // Effects for valid drop targets
    &.gd-node-builder--drag-active  .gd-node-builder__valid-container-dropzone,
    &.gd-node-builder--drag-active  .gd-node-builder__valid-widget-dropzone,
    &.gd-node-builder--drag-active  .gd-node-builder__valid-container-dropzone,
    &.gd-node-builder--drag-active > .gd-template-node--synthetic-root >  .gd-node-builder__valid-container-dropzone {
      background-color: lighten(yellowgreen, 30%);
    }

    &.gd-node-builder--drag-active  .gd-node-builder__valid-container-dropzone.--drop-target,
    &.gd-node-builder--drag-active  .gd-node-builder__valid-widget-dropzone.--drop-target {
      background-color: lighten(yellowgreen, 10%);
    }

  }


  // The elevation effect and transition
  .gd-template-node:not(.gd-template-node--synthetic-root) {
    @include mat.elevation-transition;
    @include mat.elevation(4);

    &:hover {
      @include mat.elevation(12);
    }
  }

}
