@use '@angular/material' as mat;

@mixin ui-section-title-component-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  .gd-ui-section-header {
    background-color: mat.get-color-from-palette($primary, 400);
  }

  .gd-ui-section-header__title-text {
    color: mat.get-color-from-palette($background, card);
    font-size: 18px;
  }

  .gd-ui-section-header__title-text--no-title {
    color: mat.get-color-from-palette($background, card, .5);
  }

  .gd-ui-section-header__updates-available-icon,
  .gd-ui-section-header__published-icon {
    color: mat.get-color-from-palette($background, card);
  }

}
