.gd-roles-list__tooltip-section,
.gd-roles-list__tooltip-permission {
    display: flex;
    align-items: center;

    i.icon {
      margin-right: 10px;
    }

    &.--pl-30 {
      padding-left: 30px;
    }
}

.gd-roles-list__tooltip-section {
    margin-bottom: 5px;
}