html, body {
  min-height: 100%;
  height: 100%;
  line-height: normal;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

mat-sidenav-container {
  height: 100%;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

pre {
  margin: 0;
}

code {
  white-space: pre;
}

// DEMO STYLES
.demo-preview {
  background: #F2F2F2;

  button {
    //margin: 12px;
  }

  mat-card {
    margin: 24px 0;
  }

  mat-card + mat-card {
    margin: 0 0 24px;
  }

  mat-card > mat-card-content > * {
    margin: 10px 5px;
  }
}

h3, h4, h5, p {
  margin: 0 0 16px;
}

h4 {
  font-weight: 500;
  color: #777777;
  font-size: 18px;
}

p {
  line-height: 1.5;
}

@media only screen and (min-width: 1280px) {
  .demo-tabs {
    overflow-x: hidden;
  }
}

.inline {
  display: inline-block;
  padding: 0 0.5em;
  white-space: normal;
  vertical-align: text-bottom;
}

[mat-prefix] > mat-icon, [mat-suffix] > mat-icon {
  font-size: 18px;
  vertical-align: middle;
  height: 16px;
  width: 22px;
}

.demo-list {
  margin: 24px 0;
  background: #FFF;
  width: 350px;
}

.subline {
  color: #999999;
}


.widget {
  margin: 12px;
}

.border-radius {
  border-radius: 50%;
}

.middle {
  vertical-align: middle;
}

.quickpanel .mat-tab-body-content,
.quickpanel .mat-tab-body-wrapper,
.quickpanel mat-tab-body,
.quickpanel mat-tab-group {
  height: 100%;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.mt0 {
  margin-top: 0 !important;
}

