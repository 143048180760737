/* These are global styles tat get loaded after all other style files in the static css files list */

// full screen Froala editor over modals fix
// this reduces the z-index of froala full screen wrapper to be under
// the z-index of the modal dialogues for embedding content
.fr-fullscreen-wrapper {
  z-index: 990 !important;
}

.fr-fullscreen-wrapper #froala-content-wrapper {
  padding-bottom: 48px;
}


// this should fix the issue with the Froala link edit popup being misplaced
// in edit widget dialog on article edit
.mat-dialog-container {
  position: relative;
}
