.social-card {
  position: relative;

  [mat-card-avatar] {
    margin-right: 4px;
    width: 40px;
  }

  mat-card-subtitle {
    margin-bottom: 4px;
  }
}

.activity-card {
  position: relative;
  background: white;
  padding: 0;

  .title-container {
    padding: 12px 18px;
    color: #757575;
    background: #EEEEEE;
    border-bottom: 1px solid #E1E1E1;

    .title {
      font-weight: 500;
    }

    .extra {
      font-size: 13px;

      .arrow {
        width: 14px;
        height: 14px;
        font-size: 14px;
        vertical-align: sub;
      }
    }
  }

  mat-list {
    padding: 0;

    .extra {
      color: #757575;
    }
  }
}

.traffic-sources-card {
  position: relative;
  background: white;
  padding: 0;

  .title-container {
    padding: 12px 18px;
    color: #757575;
    background: #EEEEEE;
    border-bottom: 1px solid #E1E1E1;

    .title {
      font-weight: 500;
    }

    .extra {
      font-size: 13px;
    }
  }

  .content {
    padding: 12px 18px;

    .section {
      padding: 12px 0;

      .source {
        color: white;
        border-radius: 15px;
        padding: 5px 0;
        font-size: 16px;
      }

      .value {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
