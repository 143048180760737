@import '../styles/_var.scss';
// Color variables with their default values
// $color__sidenav-list: #A7A9AE;
// $background-color__sidenav-toolbar: #272B35;
// $background-color__sidenav-list: #2B303B;
// $background-color__main-toolbar: #FFF;
// $background-color__content: #EEE;
@mixin fury-theme($color__sidenav-list, $background-color__sidenav-toolbar, $background-color__sidenav-list, $background-color__main-toolbar, $background-color__content) {

    // calculate collors for the sidenav nested items
    $background-color__sidenav-list__hover: darken($background-color__sidenav-list, 2);
    $background-color__sidenav-list__active: $background-color__sidenav-list__hover;
    $background-color__sidenav-list__level1: darken($background-color__sidenav-list, 2.5);
    $background-color__sidenav-list__level1__hover: darken($background-color__sidenav-list__level1, 1.5);
    $background-color__sidenav-list__level1__active: $background-color__sidenav-list__level1__hover;
    $background-color__sidenav-list__level2: darken($background-color__sidenav-list__level1, 2.5);
    $background-color__sidenav-list__level2__hover: darken($background-color__sidenav-list__level2, 1.5);
    $background-color__sidenav-list__level2__active: $background-color__sidenav-list__level2__hover;
    $background-color__sidenav-list__level3: darken($background-color__sidenav-list__level2, 2.5);
    $background-color__sidenav-list__level3__hover: darken($background-color__sidenav-list__level3, 1.5);
    $background-color__sidenav-list__level3__active: $background-color__sidenav-list__level3__hover;

    // admin.component.scss
    .main-toolbar {
        background: $background-color__main-toolbar;
    }
    @media screen and (min-width: 960px) {
        .sidenav+.cdk-visually-hidden+.mat-sidenav-content,
        .sidenav+.mat-sidenav-content {
            background: $background-color__content;
        }
    }
    .sidenav {
        background: $background-color__sidenav-list;
    }

    // NOTE: themenig treatment of the componet deffered, background should be added?
    // quickpanel.component.scss
    // ms-quickpanel p {
    //     color: #444;
    // }
    // ms-quickpanel h1 {
    //     color: #212121;
    // }

    // NOTE: themenig treatment of the componet deffered
    // search.component.scss

    // NOTE: themenig treatment of the componet deffered
    // favourites.component.scss
    // .favorites-container {
    //     .add-favorite {
    //         color: #FFB300;
    //     }
    //     .favorite {
    //         color: rgba(0,0,0, 0.54);
    //     }
    // }
    // .favorite-menu .favorite-search mat-icon {
    //     color: rgba(0,0,0,.54);
    // }


    // sidenav
    mat-toolbar.mat-toolbar.sidenav-toolbar {
        background: $background-color__sidenav-toolbar;
    }
    .icon-sidenav-toggle {
        color: $color__sidenav-list;
    }

    // sidenav items
    .sidenav-toplevel {
        // Every sidenav-anchor in this sidenav
        .sidenav-anchor {
            position: relative;
            cursor: pointer;
            color: $color__sidenav-list;
            transition: color .1s;
            &:hover {
                color: #FFFFFF;
            }
            &.active {
                color: #FFFFFF;
            }
        }
        >.sidenav-item {
            // Top Level Item
            >.sidenav-anchor {
                &:hover {
                    background: #333333;
                }
                &.active {
                    background: #333333;
                }
            }
            >.sidenav-list-nested>.sidenav-item {
                // Level 1 aka 1st Dropdown
                background: #333333;
                >.sidenav-anchor {
                    &:hover {
                        background: #585858;
                    }
                    &.active {
                        background: #585858;
                    }
                }
                >.sidenav-list-nested>.sidenav-item {
                    // Level 2
                    background: #616161;
                    >.sidenav-anchor {
                        padding-left: $padding__sidenav-item__level2;
                        &:hover {
                            background: $background-color__sidenav-list__level2__hover;
                        }
                        &.active {
                            background: $background-color__sidenav-list__level2__active;
                        }
                    }
                    >.sidenav-list-nested>.sidenav-item {
                        // Level 3
                        background: $background-color__sidenav-list__level3;
                        >.sidenav-anchor {
                            padding-left: $padding__sidenav-item__level3;
                            &:hover {
                                background: $background-color__sidenav-list__level3__hover;
                            }
                            &.active {
                                background: $background-color__sidenav-list__level3__active;
                            }
                        }
                    }
                }
            }
        }
    }
}
