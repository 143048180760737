/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700');
@import 'styles/_var.scss';
// @import 'styles/_tables.scss';
// @import 'styles/_charts.scss';
@import 'styles/_cards.scss';
// @import "styles/_inbox.scss";
// @import "styles/_calendar.scss";
@import 'styles/_data-list.scss';
@import 'styles/_misc.scss';
@import 'styles/roles-permissions-tooltip.scss';
@import 'styles/form-image';
@import '@splidejs/splide/css';
@keyframes pageLoad {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

body {
  overflow-y: hidden;
}

.page-load-animation {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: pageLoad linear 1;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  pointer-events: none;
  opacity: 1;
  background: #00121e;
  animation-fill-mode: forwards;
}

// Dragula style override for avoiding the DOM exception
// TODO handle more gracefully
.gu-transit {
  pointer-events: none;
  opacity: 0.7 !important;
}

// mat chip list override}
.gd-page__keyword-list .mat-chip {
  margin: 0 8px 8px 0 !important;
}

// Image Preview Panel Styles
.gd-image-preview-panel {
  width: 100%;
  height: 100%;
}

.gd-image-preview-panel .mat-dialog-container {
  position: relative;
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: none;
}

// Embed Image Upload Panel Styles
.gd-embed-upload-panel {
  align-self: center;
  & .mat-dialog-container {
    padding: 0px;
    overflow: hidden;
  }
}

.gd-embed-upload-panel--crop-active {
  transition: min-width 0.3s, max-width 0.3s, min-height 0.3s, max-height 0.3s;
  width: 100% !important;
  max-width: 1200px !important;

  height: 100vh !important;
  max-height: 100vh !important;
  min-height: 100vh !important;

  &::before {
    content: " ";
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
  }
}

.gd-field-builder__component-wrapper .mat-form-field-subscript-wrapper  {
  position: relative !important;
}

.gd-create-new-folder-panel {
  align-self: center;
  & .mat-dialog-container {
    padding: 0px;
    overflow: hidden;
  }
}

//Move-to styles
.gd-move-to-panel {
  align-self: center;
  & .mat-dialog-container {
    padding: 0px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 100% !important;
    max-height: 100% !important;
  }
}

// Workflow panel
.cdk-overlay-backdrop.gd-image-preview-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.8;
  background: #000000;
}

.gd-workflow-panel-wrapper .gd-workflow-panel {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  width: 600px;
  padding: 12px 24px;
  padding: 12px 24px;
  transition: 0.5s all;
  transform: translateX(650px);
  border: 1px solid #EEEEEE;

}

.gd-workflow-status {
  transition: 0.5s transform;
}

.gd-workflow-panel-wrapper.--panel-pinned .gd-workflow-panel,
.gd-workflow-panel-wrapper:hover .gd-workflow-panel {
  transform: translateX(0px) !important;
}

.gd-workflow-panel__pin-workflow-panel {
  cursor: pointer;
  transition: 0.3s transform;
  margin: 0 5px !important;
}

.--panel-pinned .gd-workflow-panel__pin-workflow-panel {
  transform: rotateZ(180deg);

  mat-icon {
    margin-top: 15px;
  }
}

.gd-upload-image-meta-panel .mat-dialog-container {
  padding: 0px;
  overflow: hidden;
}

.main-container .gd-item-list__button {
  width: 100%;
  min-width: 45px;
  max-width: 55px;
  align-items: center;
}

/* --------- Dragula style helpers -------- */

/* in-flight clone */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  pointer-events: none;
}

/* high-performance display:none; helper */

.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
  pointer-events: none;
}

.tribute-container.custom {
  width: 350px;
  border: 1px solid lightgray;
  padding-top: 40px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;

  &.--hidden {
    display: none !important;
  }

  &::before, &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    border-bottom: 2px solid #ccc;
    background: lightgray;
    font-size: 15px;
  }

  &.--article-mention::before {
    content: 'Select Article';
  }

  &.--post-mention::before {
    content: 'Select Post';
  }

  &.--taxonomy-mention::before {
    content: 'Select Taxonomy';
  }

  &.--article-mention,
  &.--post-mention,
  &.--taxonomy-mention {
    li {
      display: grid;
    }
  }

  &.--data-token::before {
    content: 'Select Token';
  }

  &::after {
    justify-content: flex-end;
    background: none;
    content: 'Esc to exit';
  }

  ul {
    background-color: #fff;
    max-height: 250px;
    overflow: hidden auto;
  }

  li {
    display: block;
    align-items: center;
    padding: 10px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;

    &.highlight {
      background: rgba(0, 0, 0, 0.04);
    }
    span {
      font-weight: initial;
    }
  }
}

.highlighted-article-search-terms__wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  line-height: 20px;
}

.highlighted-article-search-terms__headline {
  //color: #000000 !important;
  font-size: 14px;
}

.highlighted-article-search-terms__catchline {
 font-size: 13px;
 color:  #8c8c8c !important;
}

.gd-cp-section__title-label-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(50%);

  img {
    width: 16px;
  }
}

.arrow-taxonomy-icon {
  width: 22px !important;
  height: 22px !important;

  vertical-align: middle;

  opacity: 0.5;

  font-size: 20px;
  margin-top: 4px;

  &.--card {
    height: 11px !important;
    width: 11px !important;
    line-height: 11px;
    font-size: 11px;
    margin-top: -1px;
  }
}

.gd-resource-locked::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 201;
  background: rgba(0,0,0,0.2);
  cursor: not-allowed;
  min-height: 100vh;
}

::ng-deep .taxonomy-path-tooltip {
  white-space: pre-line;
  font-size: 13px;
}

mat-option.taxonomy-autocomplete-option {
  line-height: 32px;
  mat-icon {
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.5);
  }

  .taxonomy-path-part {
    color: rgba(0, 0, 0, 0.5) !important;

    &:last-child {
      color: #000000 !important;
    }
  }
  .taxonomy-path-arrow {
    transform: rotate(90deg)
  }
}

.gd-check-more-tooltip-wrapper-item {
  &.--deactivated {
    .gd-check-more-tooltip-item {
      background-color: #ECEEF8 !important;
      color: #8C8E96 !important;
    }
  }
}

.gd-check-more-tooltip-item {
  padding: 4px 6px;
  background-color: #3f51b5;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  white-space: nowrap;
  margin: 2px;
  font: normal normal normal 12px/14px Roboto;
}

.gd-taxo-tooltip-wrapper-item {
  display: flex;
  align-items: center;
}

.gd-item-tooltip-wrapper-chip {
  display: flex;
  align-items: center;
  &.--deactivated {
    .gd-item-tooltip-chip {
      background-color: #ECEEF8 !important;
      color: #8C8E96 !important;
    }
  }
}


.gd-item-tooltip-chip {
  padding: 4px 6px;
  background-color: #3f51b5;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  margin: 2px;
  font: normal normal normal 12px/14px Roboto;
  max-width: 400px;
  word-wrap: break-word;
  word-break: break-all;
  height: auto;
}


.gd-test-theme mat-option.taxonomy-autocomplete-option {
  mat-icon {
    color: #e3e5e4 !important;
  }

  .taxonomy-path-part {
    color: #e3e5e4 !important;
    opacity: 0.4;

    &:last-child {
      color: #ffffff !important;
      opacity: 1
    }
  }
}
.mat-snack-bar-container {
  max-width: 45vw !important;
}


// shared animation
@keyframes opacity-pulse-animation {
  0% { opacity: 1; }
  25% { opacity: 0.35; }
  50% { opacity: 1; }
  75% { opacity: 0.35; }
  100% { opacity: 1; }
}

// global scrollbar style for autocomplete fields
.gd-scroller-custom,
.mat-autocomplete-panel.mat-autocomplete-visible {
    scrollbar-color: #cccccc rgba(0, 0, 0, .06);
    scrollbar-width: thin;
}

.gd-scroller-custom::-webkit-scrollbar-track,
.mat-autocomplete-panel.mat-autocomplete-visible::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.gd-scroller-custom::-webkit-scrollbar,
.mat-autocomplete-panel.mat-autocomplete-visible::-webkit-scrollbar {
  width: 8px;
  opacity: 0.9;
  border-radius: 10px;
  background-color: none;
  height: 6px;
}

.gd-scroller-custom::-webkit-scrollbar-thumb,
.mat-autocomplete-panel.mat-autocomplete-visible::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cccccc;
}

ul#fr-editor-preferences {
  li a {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;

    input[type='checkbox'] {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

.gd-test-theme .gd-scroller-custom::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

.gd-test-theme .gd-scroller-custom::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}

.gd-article-type__sidebar-option,
.gd-article-form__sidebar-actions {
  mat-icon[data-mat-icon-name="glideCustomIcon_gaia_drafting_assistant"],
  mat-icon[data-mat-icon-name="glideCustomIcon_gaia_summary"],
  mat-icon[data-mat-icon-name="glideCustomIcon_scratchpad"] {
    svg {
      max-width: 19px;
    }
  }
}

.gd-test-theme mat-icon,
.gd-image-generate-icon {
  path.icon-main-part {
    fill: white;
  }
}

mat-icon.disabled {
  path.icon-main-part,
  path.icon-gaia-part {
    fill: grey;
  }
}

.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_image_generation"],
.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_drafting_assistant"],
.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_summary"],
.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_preflight"],
.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_translation"],
.gd-test-theme mat-icon[data-mat-icon-name="glideCustomIcon_gaia_voice"],
.gd-image-generate-icon {
  path.icon-main-part {
    fill: white;
  }
}

.mat-datepicker-toggle .mat-datepicker-toggle-default-icon {
  height: 20px !important;
  width: 20px !important;
}

/* WebKit Browsers (Chrome, Safari, Edge) */
.gd-custom-scroller::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.gd-custom-scroller::-webkit-scrollbar-track {
  background: #edeef2;
  border-radius: 10px;
}

.gd-custom-scroller::-webkit-scrollbar-thumb {
  background-color: #c5c7d3;
  outline: 1px solid #edeef2;
  border-radius: 10px;
}

/* Firefox browser */
@-moz-document url-prefix() {
  .gd-custom-scroller {
    scrollbar-width: thin;
    border-radius: 10px;
    scrollbar-color: #c5c7d3 #edeef2;
  }
}


//new global style for scroller

.gd-custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.gd-custom-scrollbar::-webkit-scrollbar-track {
  background: #EDEEF1;
  border-radius: 5px;
  border: 5px;
}

/* Handle */
.gd-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #C5C8D3;
  border-radius: 5px;
}

/* Handle on hover */
.gd-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: mix(#C5C8D3, #000, 91%);
}

.gd-custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.gd-custom-scrollbar::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}
