// Sidenav
$color__sidenav-list: #A7A9AE;

$background-color__sidenav-toolbar: #272B35;

$background-color__sidenav-list: #2B303B;
$background-color__sidenav-list__hover: darken($background-color__sidenav-list, 2);
$background-color__sidenav-list__active: $background-color__sidenav-list__hover;

$background-color__sidenav-list__level1: darken($background-color__sidenav-list, 2.5);
$background-color__sidenav-list__level1__hover: darken($background-color__sidenav-list__level1, 1.5);
$background-color__sidenav-list__level1__active: $background-color__sidenav-list__level1__hover;

$background-color__sidenav-list__level2: darken($background-color__sidenav-list__level1, 2.5);
$background-color__sidenav-list__level2__hover: darken($background-color__sidenav-list__level2, 1.5);
$background-color__sidenav-list__level2__active: $background-color__sidenav-list__level2__hover;

$background-color__sidenav-list__level3: darken($background-color__sidenav-list__level2, 2.5);
$background-color__sidenav-list__level3__hover: darken($background-color__sidenav-list__level3, 1.5);
$background-color__sidenav-list__level3__active: $background-color__sidenav-list__level3__hover;

$background-color__sidenav-list__level4: darken($background-color__sidenav-list__level3, 2.5);
$background-color__sidenav-list__level4__hover: darken($background-color__sidenav-list__level4, 1.5);
$background-color__sidenav-list__level4__active: $background-color__sidenav-list__level4__hover;

$background-color__sidenav-list__level5: darken($background-color__sidenav-list__level4, 2.5);
$background-color__sidenav-list__level5__hover: darken($background-color__sidenav-list__level5, 1.5);
$background-color__sidenav-list__level5__active: $background-color__sidenav-list__level5__hover;

$background-color__sidenav-list__level6: darken($background-color__sidenav-list__level5, 2.5);
$background-color__sidenav-list__level6__hover: darken($background-color__sidenav-list__level6, 1.5);
$background-color__sidenav-list__level6__active: $background-color__sidenav-list__level6__hover;

$padding__sidenav-item__level1: 10px;
$padding__sidenav-item__level2: 20px;
$padding__sidenav-item__level3: 30px;
$padding__sidenav-item__level4: 40px;
$padding__sidenav-item__level5: 50px;
$padding__sidenav-item__level6: 60px;

$width__sidenav: 250px;

$padding-left-right__sidenav-list__mat-list-item: 24px;
$margin-left__sidenav-list__item-name: $padding-left-right__sidenav-list__mat-list-item;
$width-height__sidenav-list__icon: 21px;

$max-width__icon-sidenav: ($padding-left-right__sidenav-list__mat-list-item * 2) + $width-height__sidenav-list__icon + 2;

$z-index__mat-sidenav: 4;
$z-index__mat-sidenav-content: 5;
$z-index__main-toolbar: 4;

// the height must be perfect for froala floating toolbar not to break
$height__main-toolbar: 36px;

$height__floating-header: 48px;

// this is the above 36px + 48px of the floating section header
$height__main-toolbar--floating--header: $height__main-toolbar + $height__floating-header;

// Mixins

@mixin matShadow-z3 () {
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
}
