@use '@angular/material' as mat;

@mixin user-menu-theme($theme) {

  // retrieve variables from theme
  $accent: map-get($theme, accent);

  .gd-user-menu__accounts--active {
    color: mat.get-color-from-palette($accent, 300);
  }

  .gd-user-menu__accounts--active span {
    border-bottom: 2px solid mat.get-color-from-palette($accent, 300);
  }

}
