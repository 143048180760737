.gd-promo-image__thumbnail-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s ease;
  color: #FFF;
  background-color: rgba(#000, .55);
  &.--active {
    opacity: 1;
    background-color: rgba(#000, .55);
  }
}

.gd-promo-image__thumbnail-overlay:hover {
  opacity: 1;
}

.gd-promo-image__thumbnail-overlay--action-icons {
  height: 100%;
  & span {
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .2s ease-in-out;
  }
  & span:hover {
    background-color: rgba(#000, .55);
  }
  & span mat-icon {
    font-size: 28px;
  }
}

.gd-promo-image__image-wrapper .mat-card-image:first-child{
  margin-top: 0px;
}

.gd-promo-image__container{
  padding-top: 20px;
}

.gd-promo-image__image-wrapper {
  position: relative;
  margin: 10px 0px;
  min-height: 180px;
  max-height: 290px;
  max-width: 300px;
  .promo-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.--small-image {
      object-fit: scale-down;
      background-color: #d5d5d5;
    }
    &.active-preview {
      animation: fadeIn 2s;
    }
    &.disabled {
      cursor: default;
    }
    &.--image {
      height: 290px;
    }
    &.--card {
      max-height: 290px;
    }
  }

  .promo-image-placeholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 220px;
    background-color: #E0E0E0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    &.disabled {
      cursor: default;
    }
    .mat-icon {
      width: auto;
      height: auto;
      font-size: 48px;
      color: #757575;
    }
    h5 {
      text-align: center;
    }
    span {
      display: block;
    }
  }
}
