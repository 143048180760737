@use '@angular/material' as mat;
@import './_fury-theme.mixin.scss';
@import './../app/shared/user-menu/user-menu.scss-theme';
@import './../app/glide-create/+site-builder/+templates/node-builder/node-builder.component.scss-theme';
@import './../app/glide-create/+site-builder/+templates/nodes-palette-item/nodes-palette-item.scss-theme';
@import './../app/glide-create/+site-builder/+routes/page-routes-list/page-routes-list.component.sccs-theme.scss';

@import './../app/shared/ui-section-title/ui-section-title.component.scss-theme.scss';
@import './../app/shared/section-info/section-info.component-theme.scss';

@import './../app/glide-media/+images/images-view/images-view.component.scss-theme';
// Include the common styles for Angular Material
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Default theme
// Define the default theme
$default-primary: mat.define-palette(mat.$indigo-palette);
$default-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$default-theme: mat.define-light-theme($default-primary, $default-accent);
// Include the default theme styles.
@include mat.all-legacy-component-themes($default-theme);
// make defualt styles for custom components
@include node-builder-component-theme($default-theme, mat.$purple-palette);
@include node-palette-item-component-theme($default-theme, mat.$purple-palette);
@include ui-section-title-component-theme($default-theme);
@include section-info-component-theme($default-theme);
@include user-menu-theme($default-theme);
@include page-route-list-theme($default-theme);
@include images-view-theme($default-theme);
// End Default theme


// Custom theme One (gd-test-theme)
// Define an alternate dark material theme.
$dark-primary: mat.define-palette(mat.$blue-palette, 900);
$dark-accent: mat.define-palette(mat.$blue-palette, 600);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent);
// add the gd-test-theme class to the mat-sidenav-container in admin component to see the test theme in action
.gd-test-theme {
    // include alternative theme for fury components
    @include fury-theme(gray, black, gray, white, #EEEEEE);

    // include alternative theme for material components
    @include mat.all-legacy-component-themes($dark-theme);
    // apply mixins for custom components
    @include node-builder-component-theme($dark-theme);
    @include node-palette-item-component-theme($dark-theme);
    @include ui-section-title-component-theme($dark-theme);
    @include user-menu-theme($dark-theme);
    @include section-info-component-theme($dark-theme);
    @include page-route-list-theme($dark-theme);

    .main-container,
    .sidenav + .cdk-visually-hidden + .mat-sidenav-content, .sidenav + .mat-sidenav-content {
      background: #121212;
    }

    //FROALA DARK START
    .fr-toolbar.fr-top,
    .fr-toolbar,
    .fr-box.fr-basic .fr-wrapper,
    textarea.fr-code {
      background: #1e1e1e;
      color: rgba(255, 255, 255, 0.87);
    }

    .fr-box.fr-inline {
      border: 1px solid transparent;
    }

    .fr-toolbar .fr-command.fr-btn i,
    .fr-toolbar .fr-command.fr-btn,
    .fr-popup .fr-input-line input,
    .fr-box.fr-basic .fr-element,
    .fr-popup .fr-buttons .fr-btn i,
    .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a,
    .gd-image-caption {
      color: rgba(255, 255, 255, 0.87) !important;
    }

    .fr-toolbar .fr-command.fr-btn.fr-dropdown::after {
      border-top: 4px solid rgba(255, 255, 255, 0.87);
    }

    .gd-article-form--show-notes {
      box-shadow: 0px 3px 6px #00000045;
    }

    .fr-box {
        border-radius: 5px;
        border: 1px solid rgb(98, 98, 98);
    }

    .gd-cp-column,
    .fr-popup .fr-buttons,
    .fr-popup.fr-active,
    .fr-popup .fr-input-line input+label,
    .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
      background: #424242;
    }

    .fr-desktop .fr-command:hover,
    .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active,
    .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
    .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
      background: #737373;
    }
    //FROALA DARK END

    .mat-input-element {
      caret-color: rgba(255, 255, 255, 0.87) !important;
    }

    .sidenav,
    .mat-table,
    .mat-card,
    mat-toolbar.mat-toolbar.sidenav-toolbar,
    .mat-expansion-panel,
    .mat-paginator,
    .gd-page-route-list__row,
    .promo-image-placeholder
    {
      background: #1e1e1e !important;
    }

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
      background: #ff4081;
    }
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
      background: #3f51b5;
    }

    .gd-item-list__row:hover,
    .gd-field-builder__card-wrapper {
      background-color: #333333 !important;
    }

    .mat-cell,
    .mat-card,
    .gd-test-theme .mat-footer-cell,
    .gd-data-list__cell,
    .gd-page-tree__headline,
    .gd-page-tree__header .gd-page-tree__column,
    .gd-template-tree__headline,
    .gd-template-tree__header .gd-template-tree__column,
    .gd-taxonomies-container__quick-form-title,
    .gd-taxonomies-container__filter-headline,
    .gd-taxonomy-tree__headline,
    .gd-taxonomy-tree__header .gd-taxonomy-tree__column,
    .gd-content-panels-list__label,
    .gd-roles-list__header-row,
    .gd-taxonomy-tree__link:link,
    .gd-taxonomy-tree__link:visited,
    .gd-page-tree__link:link, .gd-page-tree__link:visited,
    .gd-page-tree__link:link, .gd-page-tree__link:visited,
    .gd-files-view-list__first-column,
    .gd-template-tree__link:link, .gd-template-tree__link:visited,
    .gd-confirm-dialog__title,
    .gd-confirm-dialog__message,
    .gd-page-tree__filter-name-wrapper,
    .gd-template-tree__filter-name-wrapper,
    .gd-page-routes-list__filter-field,
    .gd-redirects-list__filter-field,
    .gd-widget-types-list__filter-field,
    .gd-html-snippet-list__filter-input,
    .gd-images-view__filter-input,
    .gd-images-view__filter-tags,
    .gd-galleries-list__filter-field,
    .gd-roles-list__filter-input,
    .gd-field-builder__card-name,
    .gd-field-builder__card-name-wrapper mat-icon,
    .gd-edit-role__labels-wrapper,
    .previewable-icon,
    .gd-ext-app-form__icon-field label
    {
      color: rgba(255, 255, 255, 0.87) !important;
    }

    .mat-button.mat-primary,
    .mat-button.mat-primary .mat-button-focus-overlay {
      color: rgba(255, 255, 255, 0.87);
      background-color: #272727;
    }
    gd-taxonomy-tree span.gd-taxonomy-tree__section-taxonomy {
      color: #1e88e5;
    }

    .mat-dialog-container {
      background: #272727;
    }

    .favorite-menu .favorite-search .favorite-search-input {
      background: #1e1e1e !important;
      border-radius: 5px !important;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      color: rgba(255, 255, 255, 0.87);
    }
    .favorite-menu .favorite-search mat-icon,
    .favorites-container .favorite mat-icon {
      color: rgba(255, 255, 255, 0.87) !important;
    }

    .toggle-children {
      background-image: url('/assets/img/baseline-dark.svg') !important;
    }

    a {
      color: #1e88e5;
    }

    .mat-form-field-appearance-outline.--invalid.mat-focused
    .mat-form-field-outline {
      color: red;
    }

    .mat-form-field-appearance-outline.--valid.mat-focused
    .mat-form-field-outline {
      color: green;
    }

    .gd-advanced-filtering-options__mat-group-wrapper .gd-form-wrapper .mat-expansion-panel-body {
      background: #333333 0% 0% no-repeat padding-box !important;
    }

    .gd-taxonomy-tree .node-content-wrapper-focused {
      background: #333333;
      box-shadow: none;
    }

    .fr-popup {
      color: white !important;
    }

}
// End Custom theme One



/* STYLE OVERRIDES */


// disabled class
.gd-btn-raised--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed !important;
}

.mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
  font-size: 19px;
  overflow: visible !important;
}

.question-icon,
.info-icon {
  font-size: 21px !important;
}
.--round-button {
  font-size: 24px;
}

.mat-icon.froala {
  display: block;
  font-size: 14px;
  width: 14px;
  margin: 12px;
  text-align: center;
  float: none;
}

.fr-qi-helper .mat-icon.froala {
  margin: 10px 9px 9px;
}

.gd-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: not-allowed !important;
}


// center the outlined form field text
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding-top: 0.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float:not(.mat-focused) .mat-form-field-label.mat-form-field-empty {
  margin-top: -0.5em;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  transition: transform .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1),
    margin .4s cubic-bezier(.25,.8,.25,1),
    width .4s cubic-bezier(.25,.8,.25,1);
}
